import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The introduction of GIS methodologies to the Geological Survey of India (GSI)
and other Indian  geo-institutes was pioneered in the late 1990s by a project,
`}<strong parentName="p">{`Indigeo`}</strong>{`, that brought ITC and the  Maastricht School of Management together in
an initiative supported by The Netherlands  government. The project was based in
renovated buildings on the GSI Hyderabad campus and  provided training for many
hundreds of earth scientists in its five-year lieftime. In 2012, the GSI
Training School will inaugurate several new buildings - an education block,
auditorium and student  residence blocks - to accommodate the burgeoning need
for such training in India. `}</p>
    <p>{`Colin Reeves visited India in February 2012 and was able to visit the GSI
Hyderabad campus and  see something of these developments. He found it very
rewarding to see the vast strides being  made and encouraging to know that, from
such small beginnings, a whole new generation of earth  scientists will get the
training necessary as the foundation of a career in the service of geology and
the people of India in the age of computer and information technology. The new
buildings are  expetced to be opened officially later in 2012. `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      